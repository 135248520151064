import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../theme";
import axios from "axios";
import { isSignedIn } from "../services/auth";
import { isBrowser } from "../helpers/LocalStorage";
import { navigate } from "gatsby";
import { withSnackbar } from "notistack";
import Copyright from "./Copyright";

class ForgotPassword extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    axios
      .post(
        `${process.env.GATSBY_API_URL}/users/forgot`,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(() => {
        if (isBrowser()) {
          navigate("/");
        }
      })
      .catch(() => {
        this.props.enqueueSnackbar("Invalid email", {
          variant: "error",
        });
      });
  };

  render() {
    if (isSignedIn()) {
      if (isBrowser()) {
        navigate(`/app`);
      }
    }

    return (
      <ThemeProvider theme={Theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ width: 100, height: 100, m: 1 }} src="/images/gan-square.png" />
            <Typography component="h1" variant="h5">
              Request Password Reset
            </Typography>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/" variant="body2">
                    Return to Login?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    );
  }
}

export default withSnackbar(ForgotPassword);
